<template>
<section class="consult_patient_detail_dahboard_wrp">
  <div class="page_main_header_section">
    <h2 class="main_heading">
      <button type="button" class="btn back_btn" @click="backArrow">
        <i class="fa fa-chevron-left" aria-hidden="true"></i>
      </button> Workspace</h2>
  </div>
  <div class="consult__patient__body__wrp">
    <div class="row">
      <div class="col pr-0">
        <div class="consult_patient_details" v-if="consultPatientDShowState">
          <div class="patient__detail__wrp" v-if="consultPatientInfo">
            <div class="patient__personal__info">
              <div class="row align-items-start">
                <div class="col-auto mr-auto">
                  <div class="patient__personal__prfl__photo_wrp">
                    <span class="patient__personal__prfl__avatar" v-if="consultPatientInfo.user">
                      <img :src="consultPatientInfo.user.profile.thumbnail" alt=""
                        v-if="consultPatientInfo.user.profile.thumbnail" class="img-fluid" />
                      <img :src="consultPatientInfo.user.profile.avatar" alt=""
                        v-if="consultPatientInfo.user.profile.avatar" class="img-fluid" />
                      <img src="@/assets/images/user_default.png" class="img-fluid" v-else />
                    </span>
                  </div>
                </div>
                <div class="col">
                  <h5 class="patient__personal__prfl__name">{{consultPatientInfo.full_name}}</h5>
                  <div class="row">
                    <div class="col-sm-4 mt-3" v-if="consultPatientInfo.age > 0"> <span class="patient__personal__prfl__label">Age :
                      <span class="ml-2">{{consultPatientInfo.age}}</span></span></div>
                    <div class="col-sm-4 mt-3" v-if="consultPatientInfo.user.profile.city"><span class="patient__personal__prfl__label">City :
                      <span class="ml-2">{{consultPatientInfo.user.profile.city}}</span></span></div>
                    <div class="col-sm-4 mt-3" v-if="consultPatientInfo.user.profile.country"><span class="patient__personal__prfl__label" >Country :
                      <span class="ml-2">{{this.getCountryName(consultPatientInfo.user.profile.country)}}</span></span></div>
                    <div class="col-sm-4 mt-3" v-if="consultPatientInfo.blood_type"><span class="patient__personal__prfl__label">Blood Type :
                      <span class="ml-2">{{consultPatientInfo.blood_type}}</span></span></div>
                    <div class="col-sm-4 mt-3" v-if="consultPatientInfo.height"><span class="patient__personal__prfl__label">Height :
                      <span class="ml-2">{{consultPatientInfo.height}} cm</span></span></div>
                    <div class="col-sm-4 mt-3" v-if="consultPatientInfo.weight"><span class="patient__personal__prfl__label">Weight :
                        <span class="ml-2">{{consultPatientInfo.weight}} kg</span></span></div>
                  </div>
                </div>
                <div class="col-auto ml-auto">
                  <h4 class="patient__personal__heading">Appointment Status</h4>
                  <div class="appointment_status_toggle_wrp mb-4">
                    <div class="appointment_status_toggle">
                      <template v-if="consultPatientInfo.payment">
                      <div class="appointment_status_toggle_text">Status : </div>
                      <div class="appointment_status_toggle_action appointemnt_status_closed"
                        v-if="consultPatientInfo.is_cancelled">
                        <label class="badge badge-danger">Cancelled</label>
                      </div>
                      <div class="appointment_status_toggle_action appointemnt_status_closed"
                        v-else-if="consultPatientInfo.is_closed">
                        <label class="badge badge-danger">Closed</label>
                      </div>
                      <div class="appointment_status_toggle_action appointemnt_status_closed"
                        v-else-if="consultPatientInfo.is_approved">
                        <label class="badge badge-success">Confirmed</label>
                        <label class="badge badge-danger cursor_pointer ml-2"
                          @click="appointmentStatusUpdatedMethod(consultPatientInfo, 'Close')">Close</label>
                      </div>
                      <div class="appointment_status_toggle_action" v-else>
                        <label class="badge badge-primary cursor_pointer"
                          @click="appointmentStatusUpdatedMethod(consultPatientInfo, 'Apporve')">Confirm</label>
                        <label class="badge badge-danger cursor_pointer ml-2"
                          @click="appointmentStatusUpdatedMethod(consultPatientInfo, 'Cancel')">Cancel</label>
                      </div>
                      </template>
                      <template v-else>
                        <div class="appointment_status_toggle_action appointemnt_status_closed"
                        v-if="consultPatientInfo.is_cancelled">
                          <label class="badge badge-danger">Cancelled</label>
                        </div>
                        <div class="appointment_status_toggle_action" v-else>
                          <label class="badge badge-warning">Payment Is Pending</label>
                          <label class="badge badge-danger cursor_pointer ml-2" @click="appointmentStatusUpdatedMethod(consultPatientInfo, 'Cancel')">Cancel</label>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="patient__personal_appointment__info pt-5">
              <span class="patient__personal__prfl__label">Date :
                <span>{{consultPatientInfo.appointment_time | LocalDateFormat}} at
                  {{consultPatientInfo.appointment_time | LocalTimeFormat}}</span></span>
              <span class="patient__personal__prfl__label" v-if="consultPatientInfo.disease">Past Illnesses :
                <span>{{consultPatientInfo.disease}}</span></span>
              <span class="patient__personal__prfl__label pt-2">Symptoms :
                <span>{{consultPatientInfo.symptoms}}</span></span>
              <div class="video_recording_step_wrp mt-3">
                <div class="row align-items-center">
                  <div class="col-auto">
                    <p class="mb-0 recorded_video_link" v-if="consultPatientInfo.video_file">This Patient has sent a
                      Video Message</p>
                    <p class="mb-0" v-else>This patient has not sent a video message.</p>
                  </div>
                  <div class="col pl-0 text-left">
                    <a :href="consultPatientInfo.video_file" target="_blank" class="btn btn-success"
                      v-if="consultPatientInfo.video_file">View</a>
                    <button class="btn btn-secondary" v-else disabled>View</button>
                  </div>
                </div>
                <div class="row align-items-center mt-3">
                  <div class="col-auto">
                    <p v-if="consultPatientInfo.doctor_video_file" class="recorded_video_link mb-0">
                      <a :href="consultPatientInfo.doctor_video_file" target="_blank">You may record a Video Message for
                        your Patient</a>
                    </p>
                    <p class="mb-0" v-else>You may record a Video Message for your Patient</p>
                  </div>
                  <div class="col pl-0 text-left">
                    <button class=" btn btn-secondary" disabled v-if="!consultPatientInfo.is_approved || consultPatientInfo.is_closed">Record</button>
                    <button class="btn" @click="videoRecordingPopupOpen(consultPatientInfo.id)"
                      :class="consultPatientInfo.doctor_video_file ? 'btn-success' : 'btn-secondary'"
                      v-else>Record</button>
                  </div>
                </div>
                <div class="row rescheduled_reason_txt mt-4 align-items-center" v-if="consultPatientInfo.is_scheduled">
                  <div class="col-auto">
                      <button class="btn btn-info" v-if="consultPatientInfo.is_scheduled" style="cursor:auto;">Appointment
                        Reschedule Request</button>
                  </div>
                  <div class="col">
                    <p>Reason - {{consultPatientInfo.reason}}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="patient__personal__info__action">
              <div class="row align-items-start">
                <div class="col text-center">
                  <ul class="consult__patient__contact_scl_icon">
                    <li>
                      <span class="contact_scl_icon__btn">
                        <template v-if="consultPatientInfo.is_approved && !consultPatientInfo.is_closed">
                          <template v-if="consultPatientInfo.is_online">
                            <button class="btn btn-primary" @click="consultPatientDShowStateMethod(consultPatientInfo)">Chat</button>
                            <!-- <span class="todo_tooltip_wrp badge_wrp">
                              <i class="fa fa-commenting" aria-hidden="true"
                                @click="consultPatientDShowStateMethod(consultPatientInfo)"></i>
                              <span class="todo_tooltip_text">You may chat with your patient</span>
                              <i class="badge badge-dark" v-if="consultPatientInfo.unread_count > 0">
                                {{consultPatientInfo.unread_count}}
                              </i>
                            </span> -->
                          </template>
                        </template>
                        <template v-else>
                          <button class="btn btn-primary" disabled>Chat</button>
                          <!-- <span class="todo_tooltip_wrp badge_wrp">
                            <i class="fa fa-commenting disabled" aria-hidden="true"></i>
                            <span class="todo_tooltip_text">You may chat with your patient</span>
                          </span> -->
                        </template>
                      </span>
                    </li>
                    <!-- <li>
                      <span class="contact_scl_icon__btn">
                        <template v-if="consultPatientInfo.is_approved">
                          <template v-if="consultPatientInfo.is_online">
                            <button v-if="callTextState" class="btn btn-video calling_txt_btn">
                              Calling
                            </button>
                            <span class="todo_tooltip_wrp" v-else>
                              <i class="fa fa-video-camera" aria-hidden="true"
                                @click="videoCallRequestFun(consultPatientInfo)"></i>
                              <span class="todo_tooltip_text">You may video call with your patient</span>
                            </span>
                          </template>
                        </template>
                        <template v-else>
                          <span class="todo_tooltip_wrp">
                            <i class="fa fa-video-camera disabled" aria-hidden="true"></i>
                            <span class="todo_tooltip_text">You may video call with your patient</span>
                          </span>
                        </template>
                      </span>
                    </li> -->
                    <!-- <li>
                      <span class="contact_scl_icon__btn">
                        <template v-if="consultPatientInfo.is_approved">
                          <i class="fa fa-phone" aria-hidden="true"></i>
                        </template>
                        <template v-else>
                          <i class="fa fa-phone disabled" aria-hidden="true" title="Appointment Not Approved"></i>
                        </template>
                      </span>
                    </li> -->
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!-- Section Loader Wrp -->
          <div class="fixloader patient__detail__loader" v-if="patientDetailsLoader">
            <div class="spinner spinner-1"></div>
          </div>
          <!-- Section Loader Wrp -->
        </div>
        <div class="consult_patient_with_chat_wrp" v-else>
          <chat-with-patient :patientInfo="consultPatientInfo">
          </chat-with-patient>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="consult_workspace_tabs_wrp">
          <div class="consult_workspace_tabs">
            <ul class="consult_workspace_tab">
              <li class="consult_workspace_tabs_item" :class="consultWorkspaceTabs == 'notes' ? 'active' : ''"
                @click="consultWorkspaceTabsActvmethod('notes')">Notes</li>
              <li class="consult_workspace_tabs_item" :class="consultWorkspaceTabs == 'remark' ? 'active' : ''"
                @click="consultWorkspaceTabsActvmethod('remark')"
                v-if="(consultPatientInfo && consultPatientInfo.is_approved)">
                Remarks</li>
              <li class="consult_workspace_tabs_item nocursor" title="Appointment Not Apporved" v-else>Remarks</li>
              <li class="consult_workspace_tabs_item" :class="consultWorkspaceTabs == 'prescriptions' ? 'active' : ''"
                @click="consultWorkspaceTabsActvmethod('prescriptions')"
                v-if="(consultPatientInfo && consultPatientInfo.is_approved)">
                Prescriptions</li>
              <li class="consult_workspace_tabs_item nocursor" title="Appointment Not Apporved" v-else>Prescriptions
              </li>
            </ul>
          </div>
          <div class="consult_workspace_tab_content">
            <div class="notes_of_patient_tab" v-if="consultWorkspaceTabs == 'notes'">
              <p class="blurb_for_services_txt">This space is meant for your personal notes and observations.</p>
              <notes-of-patient :notesOfPateintId="activeConsultPatient"></notes-of-patient>
            </div>
            <div class="prescriptions_of_patient_tab" v-if="consultWorkspaceTabs == 'prescriptions'">
              <prescriptions-of-patient :preOfPateintId="consultPatientInfo.user.id"
                :preOfAppointmentId="activeConsultPatient"></prescriptions-of-patient>
            </div>
            <div class="remark_of_patient_tab" v-if="consultWorkspaceTabs == 'remark'">
              <p class="blurb_for_services_txt">This space is for you to write your "doctor's notes" and necessary
                comments pertaining to your patient's health condition. It shall be shared with your respective patient
                after an appointment.</p>
              <notes-of-remarks :remarksOfPateintId="activeConsultPatient"></notes-of-remarks>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <video-record v-if="videRecordState" :RecordForDoctorId="RecordForDoctorId"></video-record>
  <appointment-status-popup v-if="appointmentStatusPopup" :appointmentStatusForData="appointmentStatusForData"
    :appointmentStatusForFLAG="appointmentStatusForFlag"></appointment-status-popup>
</section>
</template>


<script>
/*eslint-disable */

import Push from 'push.js';
import {bus} from '@/main';
import countries from '@/services/country.json';
import {mapGetters} from 'vuex';
import notesOfPatient from "@/components/private/appointments/notes-of-patient/";
import prescriptionsOfPatient from "@/components/private/appointments/pre-of-patient/";
import remarksOfPatient from "@/components/private/appointments/remarks/";
import videoRecording from '@/components/private/appointments/video-recording-data/';
import chatWithPatient from '@/components/private/appointments/chat-with-patient-screen/';
import appointmentStatusPopup from '@/components/private/appointments/appointment-status-change-popup/';
// import videoSocketRef from "@/videoNotificationSocket";

const $ = window.jQuery = require('jquery');
export default {
  name: 'consult-dashboard',
  components: {
    'notes-of-patient': notesOfPatient,
    'prescriptions-of-patient' : prescriptionsOfPatient,
    'notes-of-remarks' : remarksOfPatient,
    'chat-with-patient' : chatWithPatient,
    'video-record' : videoRecording,
    'appointment-status-popup' : appointmentStatusPopup,
  },
  props: ['consultID'],
  data () {
    return {
      activeConsultPatient:'',
      consultPatientInfo:{},
      patientDetailsLoader:false,
      consultWorkspaceTabs:'notes',
      consultPatientDShowState:true,
      countryList:[],
      appointmentStatusPopup:false,
      appointmentStatusForData:{},
      videRecordState:false,
      RecordForDoctorId:'',
      callTextState:false,
      selectedPatientId:'',
      appointmentStatusForFlag:'',
      selfUserInfo:null,
      appointmentStatusPopup:false,
      appointmentStatusForData:null,
      appointmentStatusForFlag:'',
    }
  },
  computed: {
    ...mapGetters([
      'getconsultPatientByIdGetters'
    ]),
    getConsultPatientDetailByIdFun(){
      this.consultPatientInfo = this.$store.getters.getconsultPatientByIdGetters.data;
      return
    },
  },
  mounted () {
    this.getConsultPatientDetailByIdFun
    this.activeConsultPatient = this.$route.params.consultID
    bus.$on('chatInitWithPatientBus', (data) => {
      this.consultPatientDShowState = data
    });
    bus.$on('appointmentStatusPopupBus', (data) => {
      this.appointmentStatusPopup = data
    });
    bus.$on('consultPatientInfoBus', (data) => {
      this.consultPatientInfo = data
    });
    bus.$on('appointmentStatusPopupBus', (data) => {
      this.appointmentStatusPopup = data
    });
    Object.keys(countries).map((key, index) => {
      this.countryList.push({
        name: countries[key].toLowerCase(),
        value: key.toLowerCase()
      })
    });
    this.countryList.sort((a, b) => (a.name > b.name) ? 1 : -1)
    bus.$on('videRecordStateBus', (data) => {
      this.videRecordState = data
    });
    bus.$on('userUpdateStatus', (data) => {
      let user = this.consultPatientListResults.find(item => {
       return item.user.id == data.userID;
      });
      if(user) {
        user.is_online = data.is_online;
      }
    });
    bus.$on('userUpdateStatus', (data) => {
      if(this.consultPatientInfo.user.id == data.userID) {
        this.consultPatientInfo.is_online = data.is_online;
      }
    });
    bus.$on('updateMessageNotification', (data) => {
      if(data.totalUnreadCount > 0){
        if(Push.Permission.has()){
          Push.create(data.msgSenderFullName, {
            body:data.senderMsgData,
            timeout:4000,
            onClick: function () {
                window.focus();
                this.close();
            }
          })
        }else{
          this.playSound(audioPlay)
        }
        if (this.consultPatientInfo.user.id == data.fromUserID){
          this.consultPatientInfo.unread_count = data.unreadCount
        }
    }
    })
  },
  methods: {
    appointmentStatusUpdatedMethod(data, flag){
      this.appointmentStatusPopup = true
      this.appointmentStatusForData = data
      this.appointmentStatusForFlag = flag
    },
    playSound (sound) {
      if(sound) {
        var audio = new Audio(sound);
        audio.play();
      }
    },
    /*
    * This Will Video Call Request Function
    */
    videoCallRequestFun(patientInfo) {
      let callerId = patientInfo.user.id
      this.callTextState = true;
      let callerFullName = this.selfUserInfo.salutation + ' ' + this.selfUserInfo.first_name + ' ' + this.selfUserInfo.last_name
      videoSocketRef.emit({
        message: `${callerFullName} is calling you.`,
        type: "videoCalling",
        displayName: callerFullName,
        callerID: this.selfUserInfo.id,
        targetId: callerId,
        second_user_id: callerId,
        'chat_data': {
          'message': 'Missed Call'
        }
      });
      videoSocketRef.$on('videoCallAcceptedNotification', this.getAnswerOfVideoCall);
      videoSocketRef.$on('videoCallDeclinedNotification', this.getDeclinedOfVideoCall);
      videoSocketRef.$on('videoCallAlertPopupClosed', this.notrecievedCall);
      videoSocketRef.$on('autoMissedCallEvent', this.autoMissedCall);
    },
    getAnswerOfVideoCall(getAnswerData) {
      if (this.selfUserInfo.id == getAnswerData.returnCallerUserId) {
        this.$router.push('/video/call/one-to-one-peer/#' + getAnswerData.videoCallRoomId)
      }
    },
    getDeclinedOfVideoCall(declinedData) {
      this.callTextState = false
    },
    notrecievedCall(notrecievedData) {
      this.callTextState = false
    },
    autoMissedCall(mCallData) {
      this.callTextState = false
    },
    consultPatientTabMethod(ID){
      if(this.activeConsultPatient === ID) return;
      this.activeConsultPatient = ID
      this.$router.push({ name: 'patientConsultDetails', params: { consultID: ID } })
    },
    consultInfoTabsMethod(tab){
      this.consultInfoTabs = tab
    },
    backArrow(){
      return this.$router.go(-1)
    },
    consultWorkspaceTabsActvmethod(status){
      this.consultWorkspaceTabs = status
      this.activeConsultPatient = this.activeConsultPatient
    },
    consultPatientDShowStateMethod(userInfo){
      this.consultPatientDShowState = false
    },
    getCountryName(countryCode) {
      if (countryCode == null) {
        countryCode = ''
      }
      let countryName = countryCode.toLowerCase()
      let country = this.countryList.filter(item => (item.value) == (countryName) || (item.name) == (countryName));
      if (country && country.length > 0) {
        country = country[0]
        country = country.name
      } else {
        country = ''
      }
      return country;
    },
    videoRecordingPopupOpen(Id){
      this.RecordForDoctorId = Id
      this.videRecordState = true
    }
  },
  watch: {
    getConsultPatientDetailByIdFun(){
      return this.$store.getters.getconsultPatientByIdGetters;
    },
  },
  beforeRouteLeave: function (to, from, next) {
    next();
  },
}
</script>



<style lang="scss" scoped>
.consult_patient_detail_dahboard_wrp{
  font-family:-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size:16px;
  .page_main_header_section{
  margin-bottom:15px;
  .main_heading{color: #ffffff;font-size: 24px;text-transform: uppercase;font-weight: bold;margin: 0px;line-height:1;}
  }
  .consult__patient__body__wrp{
  .consult_patient_name{font-size:18px;}
  .consult_patient_avatar{width:40px;height:40px;border-radius:50%;display:block;position:relative;img{width:100%;height:100%;}}
  .consult_patient_list{background: #111111;color: #f5f5f5;border: 1px solid #232323;min-height: 100%;
  .patient_list_heading_wrp{padding:10px;background:#252525;.patient_list_heading{font-size: 24px;color: #fffac1;text-transform: uppercase;margin: 0;}.btn{background: #ff3a30;color:#ffffff;text-transform: uppercase;padding:8px 10px;line-height: 1;box-shadow: none;border:none;border-radius: 3px;&:hover{background: lightskyblue;color:#000000;}}}
  .consult_patient_list_wrp{
  margin:0px;
  padding:0px;
  min-height: calc(100vh - 204px);
  overflow-y: auto;
  &::-webkit-scrollbar {
      width: 5px;
      background: transparent;
      }
      &::-webkit-scrollbar-thumb {
      background-color: #333333;
  }
  li{padding:9px 10px;border-right: 5px solid transparent;cursor: pointer;&:hover{background: #1d2226;border-right: 5px solid transparent;}&.active{background: #1d2226;border-right: 5px solid #34393c;}}
  }
  }
  .consult_patient_details{
  min-height: calc(100vh - 170px);background: #111111;color: #f5f5f5;border: 1px solid #232323;padding:25px 25px;position:relative;
  .consult_patient_name{font-size:26px;}
  .patient__personal__prfl__avatar {
      display: block;
      width: 90px;
      height: 90px;
      border-radius: 50%;
      border: 2px solid #efefef;
      position: relative;
      overflow:hidden;
      .img-fluid{
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          transform: scale(1);
          -webkit-transform: scale(1);
          -moz-transform: scale(1);
          transition: transform ease 0.5s;
          -webkit-transition: transform ease 0.5s;
          -moz-transition: transform ease 0.5s;
          -webkit-backface-visibility: hidden; /* Safari */
              backface-visibility: hidden;
              width:100%;
              height:100%;
      }
      &:hover .img-fluid{    
          transform: scale(1.1);
          -webkit-transform: scale(1.1);
          -moz-transform: scale(1.1);
      }
  }
  .patient__personal__prfl__name {
      font-size: 24px;
      text-transform: uppercase;
      margin-bottom: 8px;
      line-height: 1;
  }
  .patient__personal__prfl__label {
      font-size: 18px;
      display: block;
      line-height: 1;
      margin-bottom: 12px;
      color:#ffd400;
      &:last-child{margin-bottom:0px;}
      span{color:#ffffff;}
  }
  .btn{border:none;outline:none;box-shadow:none;padding:7px;line-height:1;border-radius:3px;min-width:65px;
      &.calling_txt_btn {
      font-size: 20px;
      background: rgba(255, 255, 255, 0.1);
      border: none !important;
      padding: 6px 10px 10px !important;
      line-height: 1;
      outline: none !important;
      box-shadow: none !important;
      color: #0aefea;
    }
    
  &.btn-primary{
    padding: 9px 45px;
    font-size: 17px;
    border-radius: 50px;
    background: none !important;
    color: #ffd400 !important;
    border: none;
    text-transform: capitalize;
    font-weight: 400;
    letter-spacing: .03em;
    line-height: 1;
    transition: all .5s ease;
    -webkit-transition: all .5s ease;
    -moz-transition: all ease .5s;
    box-shadow: none !important;
    outline: none;
    border: 2px solid #ffd400;
    font-weight: 500;
    &:hover{
      background: #ffd400 !important;
      color: #000000 !important;
    }
  }
}
  .patient__personal__info{
  .btn{min-width:55px;padding:6px;}
  .patient__personal__prfl__label{font-size:17px;margin-bottom:8px;}
  }
  .patient__personal__heading{
      font-size: 22px;
      text-transform: uppercase;
      margin-bottom: 15px;
      line-height: 1;
      color: #fff;
  }
  .patient__personal__info__action{
      position: relative;
      padding-top:40px;
      .consult__patient__contact_scl_icon{margin:0px;padding:0px;display:flex;align-items:center;justify-content:center;list-style:none;
        li{.fa{font-size: 32px;color: #0aefea;cursor: pointer;line-height:28px;&:hover{color:#ffc107}&.disabled{color:#6c757d;}}}}
  }
  }
  .consult_workspace_tabs_wrp{
  min-height: calc(100vh - 170px);background: #111111;color: #f5f5f5;border: 1px solid #232323;padding:10px;height:100%;
  .consult_workspace_tab{display:flex;align-items:center;border-bottom: 1px solid #232323;margin:0px;.consult_workspace_tabs_item{margin-left:1px;margin-bottom: -1px;padding:12px 15px;font-size:16px;line-height: 1;text-transform: uppercase;border-radius:4px 4px 0 0;cursor:pointer;border:1px solid transparent;list-style:none;
  &:hover{background: #212121;color: #c3c3c3;}
  &.active{border:1px solid #232323;background:#111111;border-bottom-color:transparent;}
  &:first-child{margin-left:0px;}
  &.nocursor{cursor:not-allowed;}
  @media only screen and (max-width:1291px) {
      padding:12px 10px;
  }
  }}
  .consult_workspace_tab_content{border: 1px solid #232323;border-top: 0px;padding: 10px;border-radius: 0px 0px 4px 4px;min-height: calc(100vh - 235px);}
  }
  }
  .patient__personal_appointment__info {
      min-height: 230px;
  }
  .consult_patient_per_info {
      position: relative;
      padding-right: 30px;
  }
  .appointemnt_status_indication{
      position: absolute;
      top: 2px;
      right: 0;
      .custom_round_switch {
          margin-bottom: 0;
          vertical-align: middle;
          display:inline-block;
          span {
              width: 20px;
              height: 20px;
              cursor: pointer;
              position: relative;    
              display: inline-block;
              border-radius: 50%;
              vertical-align: middle;
              &:before {
                  display: inline-block;
                  border-radius: 50%;
                  vertical-align: middle;
                  content: "";
                  width: 10px;
                  height: 10px;
                  background: #fff;
                  position: absolute;
                  margin: auto;
                  top: 0;
                  bottom: 0;
                  right: 0;
                  left: 0;
              }
              &.closed{
                  background: #da1b22;
              }
              &.upcoming{
                  background: #87cefa;
              }
              &.active{
                  background: #28a745;
              }
          }
      }
  }
  .video_recording_step_wrp{p{font-size:18px;color:#a9a9a9;}}
  .online-status{position: absolute;
      right:0px;
      bottom: 4px;
      border: 1px solid #9E9E9E;
      border-radius: 50%;
      width: 10px;
      height: 10px;
      background: #9E9E9E;
      &.online {
      background-color: #4CAF50;
      border-color: #4CAF50;
      }
  }
  .appointment_status_toggle_wrp{
  .appointment_status_toggle {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  font-family: 'MyriadProRegular','Lato',sans-serif;
  text-align: center;
  justify-content: start;
  .appointment_status_toggle_action{
      label {
       margin-bottom: 0;
      }
      .badge{
          font-size: 16px;
          font-weight: normal;
          padding: 8px 14px;
          line-height: 1;
          border-radius: 3px;
          &.cursor_pointer{cursor: pointer;
              &:hover{
                  background: lightskyblue !important;
                  color: #000000 !important;
              }
          }
      }
  }
  .appointment_status_toggle_text {
  margin-right: 10px;
  font-size:20px;
  color: #ffd400;
  transition:all ease 0.5s;
  -webkit-transition:all ease 0.5s;
  -moz-transition:all ease 0.5s;}
  /* Toggle button styles */
  .onoffswitch {
      position: relative;
      width:110px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      transition:all ease 0.5s;
      -webkit-transition:all ease 0.5s;
      -moz-transition:all ease 0.5s;
      }
      .onoffswitch-checkbox {
      display: none;
      }
      .onoffswitch-label {
      display: block;
      overflow: hidden;
      cursor: pointer;
      border: 0px solid #999999;
      border-radius: 20px;
      }
      .onoffswitch-inner {
      display: block;
      width: 200%;
      margin-left: -100%;
      transition: margin 0.3s ease-in 0s;
      }
      .onoffswitch-inner:before, .onoffswitch-inner:after {
      display: block;
      float: left;
      width: 50%;
      height: 25px;
      padding: 0;
      line-height: 25px;
      font-size: 18px;
      color: white;    
      box-sizing: border-box;
      font-family: 'MyriadProRegular','Lato',sans-serif;
      letter-spacing:0.5px;
      transition:all ease 0.5s;
      -webkit-transition:all ease 0.5s;
      -moz-transition:all ease 0.5s;
      }
      .onoffswitch-inner:before {
      content: "Accepted";
      padding-left: 10px;
      background-color: #36B04C;
      color: #FFFFFF;
      }
      .onoffswitch-inner:after {
      content: "Pending";
      padding-right: 10px;
      background-color: #6c757d;
      color: #FFFFFF;
      text-align: right;
      }
      .onoffswitch-switch {
      display: block;
      width: 16px;
      height: 16px;
      margin: 4px;
      background: #FFFFFF;
      position: absolute;
      top: 0;
      bottom: 0;
      right:85px;
      border: 0px solid #999999;
      border-radius: 13px;
      transition:all ease 0.5s;
      -webkit-transition:all ease 0.5s;
      -moz-transition:all ease 0.5s;
      }
      .onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-inner {
      margin-left: 0;
      }
      .onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-switch {
      right: 0px !important;
      }
      .appointemnt_status_closed{
          .onoffswitch-inner:after {
          content: "Closed";
          padding-right: 10px;
          background-color: #da1b22;
          color: #FFFFFF;
          text-align: right;
          }
          .onoffswitch-switch{right: 70px;}
          .onoffswitch{width:95px;}
      }
  }}
  .recorded_video_link{color:#fff5bf !important;}
  .recorded_video_link{a{color:#ffd400;text-decoration:none;&:hover{color:#87cefa;}}}
  .blurb_for_services_txt{
      font-size: 16px;
      margin: 0 0 10px;
      line-height: 1.3;
      color: #ffd400;
      text-align:justify;
  }
  .symptoms_list:last-child{i{display:none;}}
  .consult_patient_avatar img{
      width: 100%;
      height: 100%;
      border-radius: 50%;
  }
}
.lbil_logo {text-align: right;margin-bottom: 20px;.img-fluid{max-width:80px;}}
.rescheduled_reason_txt p {font-size: 16px;color: #a9a9a9;margin:0;text-align:justify;}
.todo_tooltip_wrp {
position: relative;
cursor: pointer;
display:inline-block;
.todo_tooltip_text {
right: auto;
position: absolute;
background: #ffdee3;
width: 275px;
padding: 2px 5px;
cursor: default;
z-index: 999;
border-radius: 0;
font-weight: 600;
line-height: 1.4;
font-family: 'MyriadProRegular','Lato',sans-serif;
font-size: 16px;
color: #000;
top: calc(100% + 0px);
border-radius: 2px;
display: none;
left: -125px;
z-index: 1000;
margin: 0px;
text-align: center;
&:before{    
border: solid;
border-color: #ffdee3 transparent;
border-width: 12px 12px 0px 0px;
top: -5px;
content: "";
display: block;
right: auto;
position: absolute;
z-index: 1000;
-webkit-transform: rotate(135deg);
transform: rotate(45deg);
left: 130px;
} 
}
&:hover .todo_tooltip_text{display:block;}
}
@media only screen and (max-width:1315px){
.consult_patient_detail_dahboard_wrp {
.patient_list_heading_wrp .patient_list_heading{font-size:18px !important;}
.consult_patient_details .btn{padding:6px !important;}
.video_recording_step_wrp p{font-size:15px !important;}
.patient_list_heading_wrp .btn{padding:6px !important;}
}
}
.badge_wrp {
  position: relative;
  height: 34px;
  line-height: 34px;
  display: inline-block;
  .badge {
      position: absolute;
      right: -5px;
      top: -7px;
      font-size: 10px;
      font-family: 'MyriadProRegular','Lato',sans-serif;
      font-style: normal;
      width: 18px;
      height: 18px;
      line-height: 17px;
      padding: 0;
      background: rebeccapurple;
      color: #fff;
      border-radius: 2px;
      z-index: 100;
      font-style: italic;
      border-radius: 20px;
      &.connectionRequest {
          left: -10px;
          background-color: #28a745;
          font-style: italic;
          border-radius: 20px;
      }
  }
}
</style>