<template>
    <!-- ADD PRESCRIPTION POPUP CODE HERE -->
<div class="add_pre_of_patient_popup">
  <div class="add_pre_of_patient_modal_center">
      <div class="add_pre_of_patient_modal_scroll">
          <div class="add_pre_of_patient_modal" v-if="preCreationDone">
              <div class="add_pre_of_patient_popup_header">
                  <div class="row align-item-center">
                      <div class="col">
                          <h3 class="add_pre_of_patient_popup_heading">Prescription </h3>
                      </div>
                      <div class="col-auto ml-auto">
                          <button class="btn btn-danger" @click="closePrescriptionPopupMethod">Close</button>
                      </div>
                  </div>
              </div>
              <div class="add_pre_of_patient_popup_body">
                  <form @submit.prevent="createPrescriptionBeforeSubmitMethod">
                      <div class="row">
                          <div class="col-sm-4">
                              <div class="form-group">
                                  <label>Drug Name <span class="required_star">*</span></label>
                                  <input class="form-control" type="text" v-model="prescriptionData.drug"
                                      v-validate="'required'" name="prescriptionData.drug"
                                      v-bind:class="{'is-invalid': errors.has('prescriptionData.drug') }"
                                      data-vv-as="drug">
                                  <small class="invalid-feedback" v-if="errors.has('prescriptionData.drug')">{{
                                      errors.first('prescriptionData.drug') }}</small>
                              </div>
                              <div class="form-group">
                                  <label>Route</label>
                                  <select class="form-control" v-model="prescriptionData.route">
                                      <option value="">Please Select</option>
                                      <option>Topical</option>
                                      <option>Oral</option>
                                      <option>Rectal</option>
                                      <option>Vaginal</option>
                                      <option>Inhalation</option>
                                      <option>Local</option>
                                      <option>Chew</option>
                                      <option>Suck</option>
                                      <option>Intradermal</option>
                                      <option>Subcutaneous</option>
                                      <option>Intramuscular</option>
                                      <option>Intravenous</option>
                                      <option>Nasal</option>
                                      <option>ear drops</option>
                                  </select>
                              </div>
                              <div class="form-group">
                                  <label>Direction <span class="required_star">*</span></label>
                                  <select class="form-control" v-validate="'required'"
                                      v-model="prescriptionData.direction" name="prescriptionData.direction"
                                      v-bind:class="{'is-invalid': errors.has('prescriptionData.direction') }"
                                      data-vv-as="direction">
                                      <!-- <option>Please Select</option> -->
                                      <option value="After Meals">After Meals</option>
                                      <option value="Before Meals">Before Meals</option>
                                  </select>
                                  <small class="invalid-feedback"
                                      v-if="errors.has('prescriptionData.direction')">{{
                                      errors.first('prescriptionData.direction') }}</small>
                              </div>
                              <div class="form-group">
                                  <label>Total Quantity</label>
                                  <input class="form-control" type="text" v-model="prescriptionData.quantity">
                              </div>
                          </div>
                          <div class="col-sm-3 ml-auto">
                              <div class="form-group">
                                  <label>Frequency <span class="required_star">*</span></label>
                                  <div class="label_checkbox_wrp">
                                      <label><input type="checkbox" value="Morning"
                                              v-model="prescriptionData.frequency"
                                              :disabled="frequencyOthersKeyRequired"> Morning</label>
                                      <label><input type="checkbox" value="Afternoon"
                                              v-model="prescriptionData.frequency"
                                              :disabled="frequencyOthersKeyRequired"> Afternoon</label>
                                      <label><input type="checkbox" value="Evening"
                                              v-model="prescriptionData.frequency"
                                              :disabled="frequencyOthersKeyRequired"> Evening</label>
                                      <label><input type="checkbox" value="Night"
                                              v-model="prescriptionData.frequency"
                                              :disabled="frequencyOthersKeyRequired"> Night</label>
                                      <input class="form-control" type="text" v-model="prescriptionData.frequency"
                                          v-validate="'required'" name="prescriptionData.frequency"
                                          v-bind:class="{'is-invalid': errors.has('prescriptionData.frequency') }"
                                          hidden :disabled="frequencyOthersKeyRequired">
                                      <small class="invalid-feedback"
                                          v-if="errors.has('prescriptionData.frequency')">The frequency field is
                                          required</small>

                                      <label><input type="checkbox" value="Others"
                                              v-on:change="frequencyOthersFun()">
                                          Others</label>
                                  </div>
                                  <div class="other_frequency_value mt-4" v-if="frequencyOthersKeyRequired">
                                      <div class="form-group mb-0">
                                          <label>Other</label>
                                          <select class="form-control"
                                              v-model="prescriptionData.frequency_others">
                                              <!-- <option>Please Select</option> -->
                                              <option value="if required">if required</option>
                                              <option value="immediately">immediately</option>
                                              <option value="once a day">once a day</option>
                                              <option value="twice a day">twice a day</option>
                                              <option value="thrice daily">thrice daily</option>
                                              <option value="four times a day">four times a day</option>
                                              <option value="every hour">every hour</option>
                                              <option value="every night at bedtime">every night at bedtime
                                              </option>
                                              <option value="every day">every day</option>
                                              <option value="every other day">every other day</option>
                                              <option value="every four hours">every four hours</option>
                                              <option value="once a week">once a week</option>
                                              <option value="three times a week">three times a week</option>
                                          </select>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="col-sm-4">
                              <div class="form-group">
                                  <label>Strength <span class="required_star">*</span></label>
                                  <div class="row">
                                      <div class="col-sm-5">
                                          <input class="form-control" type="number"
                                              v-validate="'required|decimal'" v-model="prescriptionData.strength"
                                              name="prescriptionData.strength"
                                              v-bind:class="{'is-invalid': errors.has('prescriptionData.strength') }"
                                              data-vv-as="strength">
                                          <small class="invalid-feedback"
                                              v-if="errors.has('prescriptionData.strength')">{{
                                              errors.first('prescriptionData.strength') }}</small>
                                      </div>
                                      <div class="col-sm-7 pl-0">
                                          <select class="form-control" v-validate="'required'"
                                              v-model="prescriptionData.strength_unit"
                                              name="prescriptionData.strength_unit"
                                              v-bind:class="{'is-invalid': errors.has('prescriptionData.strength_unit') }"
                                              data-vv-as="strength unit">
                                              <option value="Mg">Mg</option>
                                              <option value="Ng">Ng</option>
                                              <option value="gm">gm</option>
                                              <option value="mcg">mcg</option>
                                              <option value="%">%</option>
                                              <option value="IU">IU</option>
                                              <option value="IU/ml">IU/ml</option>
                                          </select>
                                          <small class="invalid-feedback"
                                              v-if="errors.has('prescriptionData.strength_unit')">{{
                                              errors.first('prescriptionData.strength_unit') }}</small>
                                      </div>
                                  </div>
                              </div>
                              <div class="form-group">
                                  <label>Dose <span class="required_star">*</span></label>
                                  <div class="row">
                                      <div class="col-sm-5">
                                          <input class="form-control" type="number"
                                              v-validate="'required|decimal'" v-model="prescriptionData.dose"
                                              name="prescriptionData.dose"
                                              v-bind:class="{'is-invalid': errors.has('prescriptionData.dose') }"
                                              data-vv-as="dose">
                                          <small class="invalid-feedback"
                                              v-if="errors.has('prescriptionData.dose')">{{
                                              errors.first('prescriptionData.dose') }}</small>
                                      </div>
                                      <div class="col-sm-7 pl-0">
                                          <select class="form-control" v-validate="'required'"
                                              v-model="prescriptionData.dose_unit"
                                              name="prescriptionData.dose_unit"
                                              v-bind:class="{'is-invalid': errors.has('prescriptionData.dose_unit') }"
                                              data-vv-as="dose unit">
                                              <option value="Ml">Ml</option>
                                              <option value="Unit">Unit</option>
                                              <option value="Table Spoon">Table Spoon</option>
                                              <option value="Tea Spoon">Tea Spoon</option>
                                          </select>
                                          <small class="invalid-feedback"
                                              v-if="errors.has('prescriptionData.dose_unit')">{{
                                              errors.first('prescriptionData.dose_unit') }}</small>
                                      </div>
                                  </div>
                              </div>
                              <div class="form-group">
                                  <label>Duration <span class="required_star">*</span></label>
                                  <div class="row">
                                      <div class="col-sm-5">
                                          <input class="form-control" type="number" v-validate="'required'"
                                              v-model="prescriptionData.duration" name="prescriptionData.duration"
                                              v-bind:class="{'is-invalid': errors.has('prescriptionData.duration') }"
                                              data-vv-as="duration">
                                          <small class="invalid-feedback"
                                              v-if="errors.has('prescriptionData.duration')">{{
                                              errors.first('prescriptionData.duration') }}</small>
                                      </div>
                                      <div class="col-sm-7 pl-0">
                                          <select class="form-control" v-validate="'required'"
                                              v-model="prescriptionData.duration_unit"
                                              name="prescriptionData.duration_unit"
                                              v-bind:class="{'is-invalid': errors.has('prescriptionData.duration_unit') }"
                                              data-vv-as="duration unit">
                                              <option value="Days">Days</option>
                                              <option value="Weeks">Weeks</option>
                                              <option value="Months">Months</option>
                                          </select>
                                          <small class="invalid-feedback"
                                              v-if="errors.has('prescriptionData.duration_unit')">{{
                                              errors.first('prescriptionData.duration_unit') }}</small>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="row">
                          <div class="col">
                              <button class="btn btn-info" type="submit" :disabled="submitted">Add
                                  Drug</button>
                          </div>
                      </div>
                  </form>
                  <div class="prescription_table_list_wrp mt-4"
                      v-if="prescriptionDetailsData && prescriptionDetailsData.length > 0">
                      <table class="table">
                          <thead>
                              <tr>
                                  <th>Drug Name</th>
                                  <th class="text-center">Strength</th>
                                  <th class="text-center">Duration</th>
                                  <th class="text-center">Frequency <br> (MN - AF - EN - NT)</th>
                                  <th class="text-center">Quantity</th>
                                  <th class="text-center">Action</th>
                              </tr>
                          </thead>
                          <tbody>
                              <tr v-for="(item, index) in prescriptionDetailsData" :key="item.id">
                                  <td>{{item.drug}}</td>
                                  <td class="text-center">{{item.strength}}</td>
                                  <td class="text-center">{{item.duration}}</td>
                                  <td class="text-center">
                                      <template v-if="item.frequency_others">
                                          {{item.frequency_others}}
                                      </template>
                                      <template v-else>
                                          <template v-if="item.frequency.includes('Morning')">1</template>
                                          <template v-else>0</template> -
                                          <template v-if="item.frequency.includes('Afternoon')">1</template>
                                          <template v-else>0</template> -
                                          <template v-if="item.frequency.includes('Evening')">1</template>
                                          <template v-else>0</template> -
                                          <template v-if="item.frequency.includes('Night')">1</template>
                                          <template v-else>0</template>
                                      </template>
                                  </td>
                                  <td class="text-center">{{item.quantity}}</td>
                                  <td class="text-center">
                                      <i class="fa fa-trash" @click="removPrescriptionDetailsOnList(index)"></i>
                                  </td>
                              </tr>
                          </tbody>
                      </table>
                      <div class="btn-wrp">
                          <button class="btn btn-primary" :disabled="submitted"
                              @click="submitPrescriptionBeforeSubmitMethod">Save & Next</button>
                              <img src="@/assets/images/progressbar.gif" width="40" class="ml-3" v-if="submitted">
                      </div>
                  </div>
              </div>
          </div>
          <template v-else>
              <div class="add_pre_of_patient_modal" v-if="preCreationSpclInstructionDone">
                  <div class="spcl_ins_modal">
                      <div class="form-group">
                          <label>Special Instruction</label>
                          <textarea class="form-control" v-model="prescriptionData.special_instruction" rows="5" maxlength="500"></textarea>
                      </div>
                      <button class="btn btn-success" @click="spclInstructionUpdateMethod">Submit</button>
                  </div>
              </div>
              <div class="add_pre_of_patient_modal" v-else>
                  <div class="prescription_creation_done_wrp text-center">
                      <h4>The Prescription has been successfully prescribed to the patient.</h4>
                      <p style="font-size: 20px;">If a patient hasn't uploaded an ID, and they conduct appointment, doctor should know that they didn't upload ID and must be needing to upload one otherwise they can't publish a prescription</p>
                      <div><button class="btn btn-success" @click="closePrescriptionPopupMethod">Ok</button></div>
                  </div>
              </div>
          </template>
      </div>
  </div>
</div>
<!-- ADD PRESCRIPTION POPUP CODE HERE -->
</template>

<script>
/*eslint-disable */
const $ = window.jQuery = require('jquery');
// import commonSocketRef from "@/videoNotificationSocket";
import toast from '@/services/toast';
import {bus} from '@/main';  
export default {
  name: 'recreating-pre-view-popup',
  components: {},
  props: ['appointmentId', 'recreatingPrescDATA'],
  data () {
    return {
      preCreationDone:true,
      prescriptionData:{
        drug:'',
        route:'',
        direction:'After Meals',
        frequency:[],
        duration:'',
        quantity:'',
        dose:'',
        strength:'',
        duration_unit:'Days',
        dose_unit:'Ml',
        strength_unit:'Mg',
        frequency_others:'',
        special_instruction:'',
      },
      prescriptionName:'',
      frequencyOthersKeyRequired:false,
      submitted:false,
      prescriptionDetailsData:[],
      preOfPateintId:'',
      preOfAppointmentId:'',
      preCreationSpclInstructionDone:false
    }
  },
  computed: {

  },
  mounted () {
    if(this.recreatingPrescDATA){
      this.preOfAppointmentId = this.appointmentId
      this.preOfPateintId = this.recreatingPrescDATA.patient_id
      this.prescriptionData.drug = this.recreatingPrescDATA.prescription_details[0].drug
      this.prescriptionData.route = this.recreatingPrescDATA.prescription_details[0].route
      this.prescriptionData.direction = this.recreatingPrescDATA.prescription_details[0].direction
      this.prescriptionData.frequency = this.recreatingPrescDATA.prescription_details[0].frequency
      this.prescriptionData.duration = this.recreatingPrescDATA.prescription_details[0].duration.split(' ')[0]
      this.prescriptionData.quantity = this.recreatingPrescDATA.prescription_details[0].quantity
      this.prescriptionData.dose = this.recreatingPrescDATA.prescription_details[0].dose.split(' ')[0]
      this.prescriptionData.strength = this.recreatingPrescDATA.prescription_details[0].strength.split(' ')[0]
      this.prescriptionData.strength_unit = this.recreatingPrescDATA.prescription_details[0].strength.split(' ')[1]
      this.prescriptionData.duration_unit = this.recreatingPrescDATA.prescription_details[0].duration.split(' ')[1]
      this.prescriptionData.dose_unit = this.recreatingPrescDATA.prescription_details[0].dose.split(' ')[1]
      this.prescriptionData.frequency_others = this.recreatingPrescDATA.prescription_details[0].drug
    }
  },
  methods: {
    frequencyOthersFun(){
      if(this.frequencyOthersKeyRequired == false){
        this.frequencyOthersKeyRequired = true
        this.prescriptionData.frequency = []
        this.prescriptionData.frequency_others = 'if required'
      }else{
        this.frequencyOthersKeyRequired = false
        this.prescriptionData.frequency_others = ''
      }
    },
    closePrescriptionPopupMethod(){
      bus.$emit('recreatingPrescDataPopupStateBus', false);
      $('body').removeClass('body_right_scroll_remove')
    },
    createPrescriptionBeforeSubmitMethod(){
      this.$validator.validateAll().then((result) => {
        if(result){
          this.submitted =  true
          var record = {}
          record.drug = this.prescriptionData.drug
          record.route = this.prescriptionData.route
          record.direction = this.prescriptionData.direction
          record.frequency = JSON.stringify(this.prescriptionData.frequency)
          record.quantity = this.prescriptionData.quantity
          record.duration = this.prescriptionData.duration + ' ' + this.prescriptionData.duration_unit  
          record.dose = this.prescriptionData.dose + ' ' + this.prescriptionData.dose_unit  
          record.strength = this.prescriptionData.strength + ' ' + this.prescriptionData.strength_unit 
          record.frequency_others = this.prescriptionData.frequency_others
          this.prescriptionDetailsData.push(record)
          this.submitted =  false
          setTimeout(() => {
            this.prescriptionData.drug = ''
            this.prescriptionData.route = ''
            this.prescriptionData.direction = 'After Meals'
            this.prescriptionData.frequency = []
            this.prescriptionData.duration = ''
            this.prescriptionData.quantity = ''
            this.prescriptionData.dose = ''
            this.prescriptionData.strength = ''
            this.prescriptionData.duration_unit = 'Days'
            this.prescriptionData.dose_unit = 'Ml'
            this.prescriptionData.strength_unit = 'Mg'
            this.prescriptionData.frequency_others = ''
            this.frequencyOthersKeyRequired = false
            this.$validator.reset()
          }, 400)
          return;
        }
      })
    },    
    removPrescriptionDetailsOnList(index){
      this.prescriptionDetailsData.splice(index, 1)
    },
    submitPrescriptionBeforeSubmitMethod(){
      this.submitted =  true
      let data = {
        patient:this.preOfPateintId,
        appointment:this.preOfAppointmentId,
        prescription_details :this.prescriptionDetailsData
      }
      this.$store.dispatch('createPrescriptionOfPatientMethod', data)
        .then((res) => {  
          this.prescriptionData.prescriptionID = res.data.data.id
          let notificationPayload = { 
            type:'patient_prescription',
            data_id:this.prescriptionData.prescriptionID,
            message:'patient prescription has benn created'
          }
        //   this.$store.dispatch('postNotificationFunc', notificationPayload)
          this.submitted =  false
          this.preCreationDone = false
          this.preCreationSpclInstructionDone = true
        //   commonSocketRef.emit({
        //     message: `Prescription Data`,
        //     type: "patient_prescription",
        //     patient_id:this.preOfPateintId,
        //     appointment_id:this.preOfAppointmentId,
        //   });
          toast.success(res.data.msg);
        }).catch((err) => {
          this.submitted =  false
          toast.error(err.data.user_msg || err.data.msg);
        })
      return;
    }, 
    spclInstructionUpdateMethod(){
      if(this.prescriptionData.special_instruction){
        this.submitted =  true
        let data = {
          special_instruction : this.prescriptionData.special_instruction
        }
        this.$store.dispatch('updatePrescriptionDataOfPatientMethod', {data:{data:data,id:this.prescriptionData.prescriptionID}}).then((res) => {
          this.submitted =  false
          this.preCreationSpclInstructionDone = false
        })
      }else{
        this.preCreationSpclInstructionDone = false
      }
    },
  }
}
</script>

<style lang="scss">
.add_pre_of_patient_popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2000;
    background: rgba(0, 0, 0, 0.92);
    height: 100vh;
    overflow-y:auto;
    .required_star {
        color: red;
        font-size: 20px;
    }
    .add_pre_of_patient_modal {
        background: #000;
        border-radius: 5px;
        box-shadow: 0px 0px 5px rgba(255, 255, 238, 0.3);
        width: 100%;
        max-width: 940px;
        margin: 0 auto;
    }
    .add_pre_of_patient_popup_header {
        background: #111;
        padding: 10px 15px;
        .add_pre_of_patient_popup_heading{font-size:22px;font-weight:600;margin:0;text-transform:uppercase;}
    }
    .add_pre_of_patient_popup_body{padding:20px 15px;}
    .label_checkbox_wrp label {
        display: block;
    }
    .form-control{
        background: none;
        outline: none!important;
        box-shadow: none!important;
        border: none;
        border-bottom: 1px solid #6f6f6f;
        border-radius: 0;
        padding:0px 5px;
        color: #b7b7b7;
        font-size:18px;
        option{color:#000000;}
    }
    .spcl_ins_modal{
        padding:25px;
        label{font-size: 24px !important;margin-bottom: 15px !important;}
        .form-control{border:1px solid #6f6f6f;border-radius:2px;padding:10px;}
    }
    select.form-control{text-transform:capitalize;}
    .btn{padding:6px 15px;min-width:90px;box-shadow:none !important;}
    .form-group > label{font-size:18px;margin-bottom:10px;}
    .prescription_table_list_wrp{
        .table{
            border-color:#6f6f6f;
            thead th{border-bottom:0px;border-color:#6f6f6f;color: #f5f5f5;font-weight:500;    font-size: 18px;text-transform: uppercase;}
            tbody td{border-color:#6f6f6f;color: #b7b7b7;font-size: 18px;}
            .fa{font-size:18px;cursor: pointer;}
            .fa-trash{&:hover{color:#c82333;}}
        }
    }
    .prescription_creation_done_wrp {
        padding: 50px 15px;
        h4{font-size:24px;font-weight:500;margin-bottom:50px;}
    }
    &::-webkit-scrollbar {
    width:4px;
    background: none;
    }
    &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #2f2f2f;
    }
    .add_pre_of_patient_modal_center {
        display: table;
        width: 100%;
        height: 100vh;
    }
    .add_pre_of_patient_modal_scroll {
        display: table-cell;
        vertical-align: middle;
    }
}
</style>