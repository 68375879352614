/*eslint-disable */

import {bus} from '@/main';
import toast from '@/services/toast';

var mediaRecorder;
var recordedBlobs;
export default {
  name: 'video-recording-data',
  components: {},
  props: ['RecordForDoctorId'],
  data () {
    return {
      recordButton:'Start Recording',
      errorMsgElement:'',
      downloadRecordVideo:false,
      storeStreamData:false,
      vidTrack:{}
    }
  },
  computed: {
  },
  mounted () {
    this.videoInitMethod()
  },
  methods: {
    videoInitMethod() {
      // const hasEchoCancellation = document.querySelector('#echoCancellation').checked;
      const constraints = {
        audio: {
          echoCancellation: {exact: true}
        },
        video: {
          width: 854, height:480
        }
      };
      // console.log('Using media constraints:', constraints);
      this.init(constraints);
    },
    startRecording(){
      // Start Recording For Code
        recordedBlobs = [];
        const options = {mimeType: "video/webm;codecs=vp9,opus"};
        // console.log(options)
        try {
          mediaRecorder = new MediaRecorder(window.stream, options);
        } catch (e) {
          console.error('Exception while creating MediaRecorder:', e);
          this.errorMsgElement= `Exception while creating MediaRecorder: ${JSON.stringify(e)}`;
          return;
        }
      
        // console.log('Created MediaRecorder', mediaRecorder, 'with options', options);
        this.recordButton = 'Stop Recording';
        this.downloadRecordVideo = false
        mediaRecorder.onstop = (event) => {
          // console.log('Recorder stopped: ', event);
          // console.log('Recorded Blobs: ', recordedBlobs);
        };
        mediaRecorder.ondataavailable = this.handleDataAvailable
        mediaRecorder.start();
        // console.log('MediaRecorder started', mediaRecorder);
    },
    stopRecording() {
      mediaRecorder.stop();
      this.downloadRecordVideo = true
    },
    uploadVideoOnDeviceMethod(){
      var sendVideoBlob = '';
      var blob = new Blob(recordedBlobs, {type: 'video/webm'})
      var reader = new FileReader();
      reader.readAsDataURL(blob); 
      reader.onloadend = function() {
        var videoBase64 = reader.result; 
        sendVideoBlob = videoBase64.replace(/^data:video\/(webm);base64,/, "")
      }
      setTimeout(() => {
        let data = {
          doctor_video_file: sendVideoBlob
        }
        this.$store.dispatch('updateVideoForPatientMethod', {data:{id:this.RecordForDoctorId, data:data}}).then((res)=>{
          this.$store.dispatch('getconsultPatientDetailByIdAction', {id:this.RecordForDoctorId}).then((res) => {
            // bus.$emit('consultPatientInfoBus', res.data.data.data)
            toast.success("Request processed successfully.");
            this.closeVideoRecordPopupMethod()
          })
        }).catch((err)=>{
          toast.error(err.data.msg);
        })
      },100)
    },
    handleSuccess(stream) {
      window.stream = stream;
      const gumVideo = document.querySelector('video#gum');
      // console.log('getUserMedia() got stream:',gumVideo,  stream);
      gumVideo.srcObject = stream;
      this.storeStreamData = true
    },
    async init(constraints) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        this.vidTrack = stream
        this.handleSuccess(stream);
      } catch (e) {
        console.error('navigator.getUserMedia error:', e);
        errorMsgElement.innerHTML = `navigator.getUserMedia error:${e.toString()}`;
      }
    },
    handleDataAvailable(event) {
      // console.log('handleDataAvailable', event.data);
      if (event.data && event.data.size > 0) {
        recordedBlobs.push(event.data);
      }
    },
    startRecordingButtonMethod(){
      if (this.recordButton === 'Start Recording') {
        this.startRecording();
      }else{
        this.stopRecording();
        this.recordButton = 'Start Recording';
    }
  },
  downloadVideoOnDeviceMethod(){
    const blob = new Blob(recordedBlobs, {type: 'video/webm'});
    const url = window.URL.createObjectURL(blob);
    // console.log(url, "Download Recording Data")
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = 'test.webm';
    document.body.appendChild(a);
    a.click();
    setTimeout(() => {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 100);
  },
  closeVideoRecordPopupMethod(){
    bus.$emit('videRecordStateBus', false)
    setTimeout(() => {
      if(this.vidTrack){this.vidTrack.getVideoTracks()[0].stop();}
    },400)
  }
  },
  watch:{
  }
}