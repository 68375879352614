<template>
<section class="notes_of_patient_wrp">
    <div class="listed_notes_of_patient_wrp" v-if="notesOfPatientFirstStep">
      <div class="notes_of_patient_header_wrp">
        <div class="row">
          <div class="col text-right">
            <button class="btn btn-success" @click="createdNotesDataSectionEnabled">+ Add</button>
          </div>
        </div>
      </div>
      <div class="notes_of_patient_body_wrp" v-if="listedOfNotesPatient">
          <ul class="notes_of_patient_listed" v-if="listedOfNotesPatientResults && listedOfNotesPatientResults.length > 0">
            <li v-for="(item, index) in listedOfNotesPatientResults" @click="updateNotesDataMethod(item)" :key="index">
              <span class="created_notes_name"><i class="fa fa-pencil"></i> {{item.title}}</span>
            </li>
          </ul>
          <div class="no_data_section" v-else>
            <div class="no_data_section_inner">
              <h4>You don't have any notes</h4>
            </div>
          </div>
      </div>
      <loader v-else></loader>
    </div>
    <!-- SECOND STEP FOR CODE HERE -->
    <div class="created_notes_data" v-if="notesOfPatientSecondStep">
      <div class="notes_of_patient_header_wrp">
        <div class="row align-items-center">
          <div class="col">
            <h5 class="notes_of_patient_heading">Add New Note</h5>
          </div>
          <div class="col-auto ml-auto text-right">
            <button class="btn btn-success" @click="savedNotesDataMethod">Save</button>
          </div>
        </div>
      </div>
      <div class="notes_of_patient_body_wrp">
        <input type="text" class="title_insert_notes_textarea" v-model="createNotesTitle" autofocus="true"
        placeholder="Title...">
        <textarea class="data_insert_notes_textarea" v-model="createNotesData" autofocus="true"
          placeholder="Description..."></textarea>
      </div>
    </div>
    <!-- THIRD STEP FOR CODE HERE -->
    <div class="updated_notes_data" v-if="notesOfPatientThirdStep">
      <div class="notes_of_patient_header_wrp">
        <div class="row align-items-center">
          <div class="col text-right">
            <button class="btn btn-danger mr-2" @click="removeNotesOfPatientPopupOpenMethod">Delete</button>
            <button class="btn btn-success" @click="goToFistStepFromThird">Update</button>
          </div>
        </div>
      </div>
      <div class="notes_of_patient_body_wrp">
        <input type="text" class="title_insert_notes_textarea" v-model="updateNotesTitle" autofocus="true"
        placeholder="Title..." @change="updatedPatientNotesDataMethod">
        <textarea class="data_insert_notes_textarea" v-model="updateNotesData" @change="updatedPatientNotesDataMethod" autofocus="true"
          placeholder="Description..."></textarea>
      </div>
    </div>
    <!-- REMOVE NOTES DATA FOR CODE HERE -->
    <div class="remove_notes_of_patient_modal" v-if="removePopupState">
      <div class="remove_notes_of_patient_modal_daillog">
        <div class="remove_notes_of_patient_modal_body">  
          <h4>This action is irreversible, are you sure?</h4>
          <div class="remove_action_btn text-center">
            <button class="btn btn-success mr-3" @click="removeNotesFromListedMethod">Yes, I want</button>
            <button class="btn btn-danger" @click="removeNotesOfPatientPopupClosedMethod">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
/*eslint-disable */
const $ = window.jQuery = require('jquery');

import loader from '@/components/common/component-small-loader';
import {
  mapGetters,
} from 'vuex';
import toast from '@/services/toast';

export default {
  name: 'notes-of-patient',
  components: {
    loader
  },
  props: ['notesOfPateintId'],
  data () {
    return {
      listedOfNotesPatient:null,
      listedOfNotesPatientResults:[],
      notesOfPatientFirstStep:true,
      notesOfPatientSecondStep:false,
      notesOfPatientThirdStep:false,
      notesOfPatientFourStep:false,
      createNotesTitle:'',
      createNotesData:'',
      updateNotesDataId:'',
      updateNotesTitle:'',
      updateNotesData:'',
      removePopupState:false
    }
  },
  computed: {
  },
  mounted () {
    this.getNotesListedOfPatientMethod(this.notesOfPateintId)
  },
  methods: {
    getNotesListedOfPatientMethod(id){
      if(!id) return;
      this.$store.dispatch('getNotesListOfPatientMethod', {id:id}).then((res)=>{
        this.listedOfNotesPatient =  res.data.data
        this.listedOfNotesPatientResults = res.data.data.results
      })
    },
    createdNotesDataSectionEnabled(){
      this.notesOfPatientFirstStep = false
      this.notesOfPatientSecondStep = true
    },
    goToFistStepFromSecond(){
      this.notesOfPatientFirstStep = true
      this.notesOfPatientSecondStep = false
    },
    goToFistStepFromThird(){
      this.notesOfPatientFirstStep = true
      this.notesOfPatientThirdStep = false
    },
    savedNotesDataMethod(){
      if(this.createNotesData.length > 0){
        let data = {
          data:this.createNotesData,
          title:this.createNotesTitle,
          appointment:this.notesOfPateintId
        }
        this.$store.dispatch('createNotesOfPatientMethod', data).then((res)=>{
          this.getNotesListedOfPatientMethod(this.notesOfPateintId)
          this.createNotesData = ''
          this.createNotesTitle = ''
          this.goToFistStepFromSecond()
        }).catch((err)=>{
          
        })
      }else{
        this.goToFistStepFromSecond()
      }
    },
    updateNotesDataMethod(data){
      this.updateNotesDataId = data.id
      this.updateNotesTitle = data.title
      this.updateNotesData = data.data
      this.notesOfPatientFirstStep = false
      this.notesOfPatientThirdStep = true
    },
    updatedPatientNotesDataMethod(){
      let data = {
        data:this.updateNotesData,
        title:this.updateNotesTitle
      }
      this.$store.dispatch('updateNotesDataOfPatientMethod',{data:{data:data,id:this.updateNotesDataId }}).then((res)=>{
        this.getNotesListedOfPatientMethod(this.notesOfPateintId)
      }).catch((err)=>{})
    },
    removeNotesOfPatientPopupOpenMethod(){
      this.removePopupState =  true
      document.body.classList.add('body_right_scroll_remove')
    },
    removeNotesOfPatientPopupClosedMethod(){
      this.removePopupState =  false
      document.body.classList.remove('body_right_scroll_remove')
    },
    removeNotesFromListedMethod(){
      this.$store.dispatch('removeNotesOfPatientMethod', {id:this.updateNotesDataId}).then((res)=>{
        toast.success(res.data.msg);
        this.goToFistStepFromThird()
        this.getNotesListedOfPatientMethod(this.notesOfPateintId)
        this.removeNotesOfPatientPopupClosedMethod()
      }).catch((err)=>{
        toast.error(err.data.msg);
      })
    },
  },
  watch:{
    notesOfPateintId(new_val, old_val){
      this.getNotesListedOfPatientMethod(new_val)
    }
  }
}
</script>
<style lang="scss" scoped>
 @import url("../../../../assets/fonts/simplicity/stylesheet.css");
.notes_of_patient_wrp{
    letter-spacing:0.5px;
    .notes_of_patient_header_wrp{background: #272727;border-radius: 2px;padding:5px 10px;}
    .notes_of_patient_body_wrp{padding:10px;}
    .btn{border:none;outline:none;box-shadow:none;padding:6px 10px;line-height:1;border-radius:2px;min-width:66px;}
    .no_data_section {
        padding-top: 100px;
        text-align: center;
        h4{font-weight:normal;font-size:20px;color:#ffffbe;}
    }
    .title_insert_notes_textarea {
    width: 100%;
    border: none;
    resize: none;
    background: none;
    outline: none;
    font-size: 22px;
    font-weight: 900;
    font-family: 'simplicitymedium';
    letter-spacing: 1px;
    line-height: 1.1;
    border-bottom: 1px solid rgba(148, 148, 148, 0.3);
    padding: 10px 0;
    color: #fff8c0;
    &::-moz-placeholder{color: #2f2f2f;}
    &::-webkit-input-placeholder{color: #2f2f2f;}
    }
    .data_insert_notes_textarea {
    width: 100%;
    height:calc(100vh - 392px);
    border: none;
    resize: none;
    background: none;
    outline: none;
    font-size: 18px;
    font-weight: 600;
    font-family: 'simplicitymedium';
    letter-spacing:1px;
    padding:10px 0px;
    white-space: break-spaces;
    color:#f1f1f1;
    &::-moz-placeholder{color: #2f2f2f;}
    &::-webkit-input-placeholder{color: #2f2f2f;}
    &::-webkit-scrollbar {
    width:4px;
    background: none;
    }
    &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #2f2f2f;
    }}
    .notes_of_patient_heading{margin-bottom:0px;color:#fffac1;font-size:16px;font-weight:normal;}
    .notes_of_patient_listed {
        margin: 0;
        padding:0;
        list-style:none;
        height: calc(100vh - 345px);
        overflow-y: auto;
        &::-webkit-scrollbar {
        width:4px;
        background: none;
        }
        &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #2f2f2f;
        }
        li{border-bottom:1px solid rgba(148, 148, 148, 0.3);color: #f1f1f1;padding:10px 0px 10px 28px;font-size: 20px;cursor:pointer;font-family: 'simplicitymedium';font-weight: bold;position: relative;line-height: 1.2;letter-spacing:1px;.fa{margin-right: 5px;position: absolute;left: 0;top: 12px;}}
    }
    .remove_notes_of_patient_modal {
    position: fixed;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.92);
    left: 0;
    right: 0;
    z-index: 1000;
    display: flex;
    align-items:center;
    .remove_notes_of_patient_modal_daillog {
    background: #000;
    width: 480px;
    margin:0px auto;
    border-radius: 5px;
    padding: 20px 25px;
    font-family: 'MyriadProRegular','Lato',sans-serif;
    color: #ffffff;
    box-shadow: 3px 3px 3px rgba(255, 255, 255, 0.04), -3px -3px 3px rgba(255, 255, 255, 0.04);
    }
    .remove_notes_of_patient_modal_body h4 {
    font-size:20px;
    color: #ffffff;
    font-family: 'MyriadProRegular','Lato',sans-serif;
    text-align: center;
    margin: 0 0 30px;
    }
    .btn{font-size:18px;padding:10px 15px;}
    }
}
</style>