<template>
<section class="remarks_of_patient_wrp">
    <div class="listed_remarks_of_patient_wrp" v-if="remarksOfPatientFirstStep">
      <div class="remarks_of_patient_header_wrp">
        <div class="row">
          <div class="col text-right">
            <button class="btn btn-success" @click="createdRemarksDataSectionEnabled">+ Add</button>
          </div>
        </div>
      </div>
      <div class="remarks_of_patient_body_wrp" v-if="listedOfRemarksPatient">
          <ul class="remarks_of_patient_listed" v-if="listedOfRemarksPatientResults && listedOfRemarksPatientResults.length > 0">
            <li v-for="(item, index) in listedOfRemarksPatientResults" @click="updateRemarksDataMethod(item)" :key="index">
              <span class="created_remarks_name"><i class="fa fa-pencil"></i> {{item.title}}</span>
            </li>
          </ul>
          <div class="no_data_section" v-else>
            <div class="no_data_section_inner">
              <h4>You don't have any remarks</h4>
            </div>
          </div>
      </div>
      <loader v-else></loader>
    </div>
    <!-- SECOND STEP FOR CODE HERE -->
    <div class="created_remarks_data" v-if="remarksOfPatientSecondStep">
      <div class="remarks_of_patient_header_wrp">
        <div class="row align-items-center">
          <div class="col">
            <h5 class="remarks_of_patient_heading">Add New Remark</h5>
          </div>
          <div class="col-auto ml-auto text-right">
            <button class="btn btn-success" @click="savedRemarksDataMethod">Save</button>
          </div>
        </div>
      </div>
      <div class="remarks_of_patient_body_wrp">
        <input type="text" class="title_insert_remarks_textarea" v-model="createRemarksTitle" autofocus="true"
        placeholder="Title...">
        <textarea class="data_insert_remarks_textarea" v-model="createRemarksData" autofocus="true"
          placeholder="Description..."></textarea>
      </div>
    </div>
    <!-- THIRD STEP FOR CODE HERE -->
    <div class="updated_remarks_data" v-if="remarksOfPatientThirdStep">
      <div class="remarks_of_patient_header_wrp">
        <div class="row align-items-center">
          <div class="col text-right">
            <button class="btn btn-danger mr-2" @click="removeRemarksOfPatientPopupOpenMethod">Delete</button>
            <button class="btn btn-success" @click="goToFistStepFromThird">Update</button>
          </div>
        </div>
      </div>
      <div class="remarks_of_patient_body_wrp">
        <input type="text" class="title_insert_remarks_textarea" v-model="updateRemarksTitle" autofocus="true"
        placeholder="Title..." @change="updatedPatientRemarksDataMethod">
        <textarea class="data_insert_remarks_textarea" v-model="updateRemarksData" @change="updatedPatientRemarksDataMethod" autofocus="true"
          placeholder="Description..."></textarea>
      </div>
    </div>
    <!-- REMOVE NOTES DATA FOR CODE HERE -->
    <div class="remove_remarks_of_patient_modal" v-if="removePopupState">
      <div class="remove_remarks_of_patient_modal_daillog">
        <div class="remove_remarks_of_patient_modal_body">  
          <h4>This action is irreversible, are you sure?</h4>
          <div class="remove_action_btn text-center">
            <button class="btn btn-success mr-3" @click="removeRemarksFromListedMethod">Yes, I want</button>
            <button class="btn btn-danger" @click="removeRemarksOfPatientPopupClosedMethod">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
/*eslint-disable */
const $ = window.jQuery = require('jquery');

// import commonSocketRef from "@/videoNotificationSocket";
import loader from '@/components/common/component-small-loader';
import {
  mapGetters,
} from 'vuex';
import toast from '@/services/toast';

export default {
  name: 'remarks',
  components: {
    loader
  },
  props: ['remarksOfPateintId'],
  data () {
    return {
      listedOfRemarksPatient:null,
      listedOfRemarksPatientResults:[],
      remarksOfPatientFirstStep:true,
      remarksOfPatientSecondStep:false,
      remarksOfPatientThirdStep:false,
      remarksOfPatientFourStep:false,
      createRemarksTitle:'',
      createRemarksData:'',
      updateRemarksDataId:'',
      updateRemarksTitle:'',
      updateRemarksData:'',
      removePopupState:false
    }
  },
  computed: {
  },
  mounted () {
    this.getRemarksListedOfPatientMethod(this.remarksOfPateintId)
  },
  methods: {
    getRemarksListedOfPatientMethod(id){
      if(!id) return;
      this.$store.dispatch('getRemarksListOfPatientMethod', {id:id}).then((res)=>{
        this.listedOfRemarksPatient =  res.data.data
        this.listedOfRemarksPatientResults = res.data.data.results
      })
    },
    createdRemarksDataSectionEnabled(){
      this.remarksOfPatientFirstStep = false
      this.remarksOfPatientSecondStep = true
    },
    goToFistStepFromSecond(){
      this.remarksOfPatientFirstStep = true
      this.remarksOfPatientSecondStep = false
    },
    goToFistStepFromThird(){
      this.remarksOfPatientFirstStep = true
      this.remarksOfPatientThirdStep = false
    },
    savedRemarksDataMethod(){
      if(this.createRemarksTitle.length > 0){
        let data = {
          data:this.createRemarksData,
          title:this.createRemarksTitle,
          appointment:this.remarksOfPateintId
        }
        this.$store.dispatch('createRemarksOfPatientMethod', data).then((res)=>{
          let notificationPayload = { 
            type:'patient_remark',
            data_id:res.data.data.id,
            message:'patient remark has benn created'
          }
          // this.$store.dispatch('postNotificationFunc', notificationPayload)
          this.getRemarksListedOfPatientMethod(this.remarksOfPateintId)
          this.createRemarksData = ''
          this.createRemarksTitle = ''
          this.goToFistStepFromSecond()
        }).catch((err)=>{
        })
        // commonSocketRef.emit({
        //   message: `Remark Data`,
        //   type: "patient_remark",
        //   patient_id:this.remarksOfPateintId
        // });
      }else{
        this.goToFistStepFromSecond()
      }
    },
    updateRemarksDataMethod(data){
      this.updateRemarksDataId = data.id
      this.updateRemarksTitle = data.title
      this.updateRemarksData = data.data
      this.remarksOfPatientFirstStep = false
      this.remarksOfPatientThirdStep = true
    },
    updatedPatientRemarksDataMethod(){
      let data = {
        data:this.updateRemarksData,
        title:this.updateRemarksTitle
      }
      this.$store.dispatch('updateRemarksDataOfPatientMethod',{data:{data:data,id:this.updateRemarksDataId }}).then((res)=>{
        let notificationPayload = { 
          type:'patient_remark_updated',
          data_id:this.remarksOfPateintId,
          message:'patient remark has benn updated'
        }
        // this.$store.dispatch('postNotificationFunc', notificationPayload)
        this.getRemarksListedOfPatientMethod(this.remarksOfPateintId)
      }).catch((err)=>{})
    //   commonSocketRef.emit({
    //     message: `Update Remark Data`,
    //     type: "patient_remark",
    //     patient_id:this.remarksOfPateintId,
    //   });
    },
    removeRemarksOfPatientPopupOpenMethod(){
      this.removePopupState =  true
      document.body.classList.add('body_right_scroll_remove')
    },
    removeRemarksOfPatientPopupClosedMethod(){
      this.removePopupState =  false
      document.body.classList.remove('body_right_scroll_remove')
    },
    removeRemarksFromListedMethod(){
      this.$store.dispatch('removeRemarksOfPatientMethod', {id:this.updateRemarksDataId}).then((res)=>{
        let notificationPayload = { 
          type:'patient_remark_removed',
          data_id:this.updateRemarksDataId,
          message:'patient remark has benn removed'
        }
        // this.$store.dispatch('postNotificationFunc', notificationPayload)
        toast.success(res.data.msg);
        this.goToFistStepFromThird()
        this.getRemarksListedOfPatientMethod(this.remarksOfPateintId)
        this.removeRemarksOfPatientPopupClosedMethod()
        // commonSocketRef.emit({
        //   message: `Remove Remark Data`,
        //   type: "patient_remark",
        //   patient_id:this.remarksOfPateintId
        // });
      }).catch((err)=>{
        toast.error(err.data.msg);
      })
    },
  },
  watch:{
    remarksOfPateintId(new_val, old_val){
      this.getRemarksListedOfPatientMethod(new_val)
    }
  }
}
</script>

<style lang="scss" scoped>
@import url("../../../../assets/fonts/simplicity/stylesheet.css");
.remarks_of_patient_wrp{
    letter-spacing:0.5px;
    .remarks_of_patient_header_wrp{background: #272727;border-radius: 2px;padding:5px 10px;}
    .remarks_of_patient_body_wrp{padding:10px;}
    .btn{border:none;outline:none;box-shadow:none;padding:6px 10px;line-height:1;border-radius:2px;min-width:66px;}
    .no_data_section {
        padding-top: 100px;
        text-align: center;
        h4{font-weight:normal;font-size:20px;color:#ffffbe;}
    }
    .title_insert_remarks_textarea {
    width: 100%;
    border: none;
    resize: none;
    background: none;
    outline: none;
    font-size: 22px;
    font-weight: 900;
    font-family: 'simplicitymedium';
    letter-spacing: 1px;
    line-height: 1.1;
    border-bottom: 1px solid rgba(148, 148, 148, 0.3);
    padding: 10px 0;
    color: #fff8c0;
    &::-moz-placeholder{color: #2f2f2f;}
    &::-webkit-input-placeholder{color: #2f2f2f;}
    }
    .data_insert_remarks_textarea {
    width: 100%;
    height:calc(100vh - 440px);
    border: none;
    resize: none;
    background: none;
    outline: none;
    font-size: 18px;
    font-weight: 600;
    font-family: 'simplicitymedium';
    letter-spacing:1px;
    padding:10px 0px;
    white-space: break-spaces;
    color:#f1f1f1;
    &::-moz-placeholder{color: #2f2f2f;}
    &::-webkit-input-placeholder{color: #2f2f2f;}
    &::-webkit-scrollbar {
    width:4px;
    background: none;
    }
    &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #2f2f2f;
    }}
    .remarks_of_patient_heading{margin-bottom:0px;color:#fffac1;font-size:16px;font-weight:normal;}
    .remarks_of_patient_listed {
        margin: 0;
        padding:0;
        list-style:none;
        height: calc(100vh - 390px);
        overflow-y: auto;
        &::-webkit-scrollbar {
        width:4px;
        background: none;
        }
        &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #2f2f2f;
        }
        li{border-bottom:1px solid rgba(148, 148, 148, 0.3);color: #f1f1f1;padding:10px 0px 10px 28px;font-size: 20px;cursor:pointer;font-family: 'simplicitymedium';font-weight: bold;position: relative;line-height: 1.2;letter-spacing:1px;.fa{margin-right: 5px;position: absolute;left: 0;top: 12px;}}
    }
    .remove_remarks_of_patient_modal {
    position: fixed;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.92);
    left: 0;
    right: 0;
    z-index: 1000;
    display: flex;
    align-items:center;
    .remove_remarks_of_patient_modal_daillog {
    background: #000;
    width: 480px;
    margin:0px auto;
    border-radius: 5px;
    padding: 20px 25px;
    font-family: 'MyriadProRegular','Lato',sans-serif;
    color: #ffffff;
    box-shadow: 3px 3px 3px rgba(255, 255, 255, 0.04), -3px -3px 3px rgba(255, 255, 255, 0.04);
    }
    .remove_remarks_of_patient_modal_body h4 {
    font-size:20px;
    color: #ffffff;
    font-family: 'MyriadProRegular','Lato',sans-serif;
    text-align: center;
    margin: 0 0 30px;
    }
    .btn{font-size:18px;padding:10px 15px;}
    }
}
</style>